/** Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts import */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

/** CSS Variables */
:root {
  --black-gradient: linear-gradient(144.39deg, #ffffff -278.56%, #6d6d6d -78.47%, #11101d 91.61%);
  --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

/** CSS Resets */
* {
  scroll-behavior: smooth;
}
body {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/** Boilerplate Built-In Gradient Colors Start */

.text-gradient {
  background: radial-gradient(64.18% 64.18% at 71.16% 35.69%, #def9fa 0.89%, #bef3f5 17.23%, #9dedf0 42.04%, #7de7eb 55.12%, #5ce1e6 71.54%, #33bbcf 100%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.bg-black-gradient {
  background: linear-gradient(144.39deg, #ffffff -278.56%, #6d6d6d -78.47%, #11101d 91.61%);
}
.bg-gray-gradient {
  background: linear-gradient(153.47deg, rgba(255, 255, 255, 0) -341.94%, #14101d 95.11%);
}
.bg-blue-gradient {
  background: linear-gradient(157.81deg, #def9fa -43.27%, #bef3f5 -21.24%, #9dedf0 12.19%, #7de7eb 29.82%, #5ce1e6 51.94%, #33bbcf 90.29%);
}
.bg-discount-gradient {
  background: linear-gradient(125.17deg, #272727 0%, #11101d 100%);
}

.white-gradient {
  background: rgba(255, 255, 255, 0.6);
  filter: blur(750px);
}
.blue-gradient {
  background: linear-gradient(180deg, rgba(188, 165, 255, 0) 0%, #214d76 100%);
  filter: blur(123px);
}
.pink-gradient {
  background: linear-gradient(90deg, #f4c4f3 0%, #fc67fa 100%);
  filter: blur(900px);
}

/** Boilerplate Built-In Gradient Colors End */

/** Boilerplate Built-In Card Style Start */

.box-shadow {
  box-shadow: var(--card-shadow);
}

.feedback-card {
  background: transparent;
}

.feature-card:hover {
  background: var(--black-gradient);
  box-shadow: var(--card-shadow);
}

.feedback-container .feedback-card:last-child {
  margin-right: 0px;
}

/** Boilerplate Built-In Card Style End */

/** And Design Customize Start */

.ant-btn-primary {
  @apply text-textColorWhite bg-primaryColor hover:bg-primaryColorHover border-primaryColor hover:border-primaryColorHover !important;
}

.ant-divider-horizontal.ant-divider-with-text-center::before,
.ant-divider-horizontal.ant-divider-with-text-center::after {
  border-top: 2px solid !important;
  @apply border-primaryColor text-center !important;
}

.ant-message-custom-content {
  @apply inline-flex items-center;
}

.ant-popover-title {
  @apply bg-backgroundColorBlack;
}

/** And Design Customize End */

/** Boilerplate Header Style Start */

.header-logo {
  @apply text-2xl text-textColorWhite font-bold uppercase transition-all duration-100 hover:text-textColorWhiteHover;
}

.navbar-menu-item {
  @apply text-textColorWhite font-bold uppercase transition-all duration-100 hover:text-textColorWhiteHover;
}

.mobile-menu-item {
  @apply text-textColorWhite uppercase hover:text-textColorWhiteHover;
}

/* hamburger button */
.hamburger {
  @apply cursor-pointer w-[24px] h-[24px] transition-all duration-100 relative;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  @apply absolute top-0 left-0 w-[24px] h-[2px] bg-backgroundColorWhite rotate-0 transition-all duration-100;
}

.hamburger-middle {
  transform: translateY(7px);
}
.hamburger-bottom {
  transform: translateY(14px);
}

.open {
  transform: rotate(90deg);
  transform: translateY(0px);
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translate(6px);
}
.open .hamburger-middle {
  display: none;
}
.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translate(-6px);
}

/** Boilerplate Header Style Start */

.acq {
  background: linear-gradient(90deg, #f2a50f, #26bcd0);
  background-clip: text;
  color: transparent;
}

/* navbar styles */
.close-btn {
  display: none;
}
.open .close-btn {
  display: block;
}
.open .open-btn {
  display: none;
}
/* nav container style */
.navbar-container {
  @apply fixed flex bg-[#121212] shadow shadow-[#2e2d2dd2] sm:shadow-none px-6 sm:p-0 sm:absolute right-0 left-0 sm:top-[27px]  justify-between h-[42px] z-[999] mx-auto sm:max-w-[89.0625%] md:max-w-[89.43%];
}

.backdrop-wrapper {
  @apply fixed transition-all scale-y-0 sm:scale-y-100 transform translate-y-full sm:-translate-y-0 top-[42px] h-screen right-0 left-0 sm:top-0 sm:relative bg-[#121212b2] sm:flex  sm:bg-transparent sm:h-[42px];
}

.items-wrapper {
  @apply fixed font-[inter]  text-white  w-[217px] p-6 flex gap-10 flex-col bg-[#121212] h-screen right-[0px] sm:p-0 sm:h-[42px] sm:right-0 sm:top-0 sm:w-full sm:bg-transparent sm:relative sm:items-center sm:flex-row sm:gap-[32px];
}

/* This shows the navlinks upon toggle */
.show {
  transform: scaleY(1);
  transform: translateY(0%);
  transition: all ease-in-out 300ms;
}
.subhero-grid-template {
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: minmax(170px, 1fr);
}

@media (max-width: 375px) {
  .subhero-grid-template {
    grid-template-columns: 1fr;
  }
}
.Landing_page {
  background: rgb(18 18 18);
}
@media (max-width: 768px) {
  .opa {
    display: none;
    padding-bottom: -4rem;
  }
}
@media (max-width: 425px) {
  .opac {
    display: none;
  }
}
/* Footer Styling */
.footer-container {
  @apply pb-4 px-6  font-[inter] bg-[#f5f5f5];
}
.footer-wrapper {
  @apply my-[50px] md:my-[100px] mx-auto sm:max-w-[93.75%]  md:max-w-[92.22%] rounded-[32px] h-[416px] flex flex-col sm:flex-row bg-[#121212] justify-between relative;
}
.contact-container {
  @apply grid relative px-5 md:px-10 sm:w-full h-full;
}
.contact-heading {
  @apply text-[12px] font-semibold leading-4 sm:text-xl sm:leading-6 text-[#25AA4A];
}
.contact-paragraph {
  @apply text-white font-semibold  leading-6 sm:leading-8 md:leading-[48px] text-[16px] sm:text-[29px] md:text-[40px];
}
.contact-illustaration-bg {
  @apply h-full w-full bg-right-bottom bg-contain bg-no-repeat;
}
.footer-contacts {
  @apply flex flex-col flex-wrap sm:flex-row justify-between gap-5 pt-[48px] mx-auto sm:max-w-[93.75%]  md:max-w-[92.22%];
}
.footer-contact-wrapper {
  @apply flex flex-col sm:flex-row gap-7 w-[219px] sm:w-[744px];
}
.footer-contact-text {
  @apply text-base text-[#A6A6A6] font-semibold;
}

.loader {
  animation: all 300ms ease-in-out;
}

.why-croissance-main-wrapper {
  @apply mt-4 sm:mt-8 md:mt-0 flex flex-col gap-16;
}
.why-croissance-sub-wrapper {
  @apply flex gap-5;
}

.why-croissance-description-wrapper {
  @apply flex flex-col gap-2;
}
